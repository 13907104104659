import React, {useRef} from 'react';
import {BuyerNote} from '../../Components/BuyerNote/BuyerNote';
import {CartItems} from '../../../../../common/components/CartItems/CartItems';
import {Coupon} from '../../Components/Coupon/Coupon';
import {Headline} from '../../Components/Headline/Headline';
import {OrderSummary} from '../../Components/OrderSummary/OrderSummary';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CartBody.scss';
import {CheckoutButtons} from '../../Components/CheckoutButtons/CheckoutButtons';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';
import {CartScreenReaderMessage} from './CartScreenReaderMessage/CartScreenReaderMessage';
import {MinimumOrderAmount} from '../../Components/MinimumOrderAmount/MinimumOrderAmount';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import {useIsAfterHydration} from '../../../../../hooks/useIsAfterHydration';

export enum CartBodyDataHook {
  CartAsideLoader = 'CartBody.aside',
  CartAsideContent = 'CartBody.content',
}

export const CartBody = () => {
  const {t} = useTranslation();

  const {
    shouldShowCoupon,
    shouldShowBuyerNote,
    shouldShowMinimumOrderAmount,
    shouldShowTopCheckoutButtonInMobile,
    shouldShowSecureCheckout,
    shouldDisplayViolations,
    isLoadingSummary,
    cartFromSDKWhenShowShippingOrShowTax,
  } = useControllerProps().cartStore;
  const {isMobile, isEditorX} = useEnvironment();
  const cartBodyRef = useRef<HTMLDivElement>(null);

  const isAfterHydration = useIsAfterHydration();

  let mainClassName = s.main;

  /* istanbul ignore next */
  if (cartBodyRef.current?.clientWidth <= 920) {
    mainClassName = 'cartBodyMainOneColumn';
  }
  return (
    <main className={mainClassName} ref={cartBodyRef}>
      <section className={s.cart}>
        {isMobile && shouldShowTopCheckoutButtonInMobile && !isEditorX && isAfterHydration && (
          <CheckoutButtons isOnTop />
        )}
        <Headline />
        <CartItems showQuantityBySettings showThumbnailBySettings shouldShowItemInfoBySettings />
        <footer className={s.footer}>
          {shouldShowCoupon && (
            <div className={s.coupon}>
              <Coupon />
            </div>
          )}
          {shouldShowBuyerNote && (
            <div className={s.buyerNote}>
              <BuyerNote />
            </div>
          )}
        </footer>
        <CartScreenReaderMessage />
      </section>
      <aside className={s.summary}>
        {cartFromSDKWhenShowShippingOrShowTax && isLoadingSummary ? (
          <div data-hook={CartBodyDataHook.CartAsideLoader} className={s.spinner}>
            Loading..
          </div>
        ) : (
          <span data-hook={CartBodyDataHook.CartAsideContent}>
            <OrderSummary />
            {shouldDisplayViolations && <Violations />}

            {shouldShowMinimumOrderAmount && <MinimumOrderAmount />}
            <CheckoutButtons />
            {shouldShowSecureCheckout && <SecureCheckout text={t('cart.orderSummary.secureCheckout.label')} />}
          </span>
        )}
      </aside>
    </main>
  );
};
